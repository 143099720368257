import useUserData from "../../hooks/useUserData";
import NavBar from "../../components/NavBar";
import { useNavigate, useOutlet } from "react-router-dom";
import { useEffect } from "react";

export default function Layout() {
  const navigate = useNavigate();
  const outlet = useOutlet();
  const user = useUserData();

  useEffect(() => {
    if (user && !outlet) {
      // default path
      navigate('/referrals');
    }
  }, [user, outlet]);

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-full">
      <NavBar />
      <div className="p-5 max-w-5xl mx-auto">
        {outlet}
      </div>
    </div>
  );
}
