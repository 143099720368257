import HttpService, { ApiError } from "./HttpService";

const ApiService = {
  login(params) {
    return HttpService.post('/partners-v1/login', params);
  },

  signup(params) {
    return HttpService.post('/partners-v1/signup', params);
  },

  me() {
    return HttpService.get('/partners-v1/me');
  },

  getReferrals() {
    return HttpService.get('/partners-v1/referrals');
  },

  getPayments() {
    return HttpService.get('/partners-v1/payments');
  },

  createReferral(values) {
    return HttpService.post('/partners-v1/referrals', values);
  },

  requestResellerCall(values) {
    return HttpService.post('/partners-v1/reseller/request-callback', values);
  },

  setFormErrors(form, error) {
    if (!(error instanceof ApiError)) {
      return;
    }

    if (error.response.status !== 422 || !error.json || !error.json.data) {
      // no errors to display
      return;
    }

    const errorFields = Object.keys(error.json.data)
      .map(key => ({
        name: key,
        errors: error.json.data[key]
      }));

    form.setFields(errorFields);
  },

};

export default ApiService;
