import create from "zustand";
import { persist } from "zustand/middleware";

type User = {
  id: number;
  code: string;
  name: string;
  email: string;
  phone: string;

  is_verified: boolean;
}

type State = {
  token: string | null;
  user: User | null;

  setToken: (token: string) => void;
  setUser: (user: User) => void;
}

const state = (set: any) => ({
  token: null,
  user: null,

  setToken(token) {
    set({ token });
  },

  setUser(user) {
    set({ user });
  },
});

const useStore = create<State>()(
  persist(state, {
    name: "ewity-partner-state",
  })
);

export default useStore;
