import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useStore from "../../store";

import DollarImage from './images/dollar.png';
import PeopleImage from './images/people.png';
import AllImage from './images/all.png';
import CommissionImage from './images/commission.png';

const heroPoints = [
  {
    title: '25% Commission for 1 Year',
    description: "With our commission structure, you'll earn a significant share of your referrals revenue.",
    icon: CommissionImage,
  },
  {
    title: 'Everyone is Welcome',
    description: 'This is an opportunity for those of you who enjoy selling and building extra monthly revenue.',
    icon: AllImage,
  },
];

export default function Auth() {
  // ensure there is no logged in user
  const navigate = useNavigate();
  const token = useStore((state) => state.token);
  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token]);

  return (
    <div className="flex flex-col flex-col-reverse lg:flex-row min-h-full">
      <div className="md:flex-1 py-12 px-4 lg:py-20">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <img
            className="h-20 w-auto"
            src="https://app.ewitypos.com/images/pos-icon-1.1-512.png"
            alt="Ewity POS"
          />

          <Outlet />
        </div>
      </div>
      <div className="lg:flex-1 bg-blue-900 flex flex-col">
        <div className="text-white max-w-lg mx-auto pt-8 pb-3 px-10 lg:pt-20 lg:pb-8">
          <img src={DollarImage} alt="USD" className="h-11 w-auto mb-2" />

          <div className="text-4xl leading-snug">Are you looking for additional reliable income?</div>
          <div className="text-blue-200 mt-2">Join our reseller program today!</div>
        </div>
        <div>
          <img src={PeopleImage} alt="People" />
        </div>

        <div className="px-8 bg-gray-100 flex-1">
          <div className="-translate-y-8">
            {heroPoints.map(point => (
              <div
                key={point.title}
                className="bg-white shadow-md p-5 mb-5 max-w-lg mx-auto rounded-lg flex flex-row items-center"
              >
                <div className="min-w-[70px] mr-5">
                  <img src={point.icon} alt={point.title} className="w-32"/>
                </div>
                <div>
                  <div className="text-lg mb-1">{point.title}</div>
                  <div className="text-gray-500 leading-tight">{point.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
