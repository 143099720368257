import {Button, Card, Form, Input, Skeleton, Tag} from "antd";
import {useMutation, useQuery, useQueryClient} from "react-query";
import ApiService from "../../services/ApiService";
import {useState} from "react";
import * as dayjs from "dayjs";
import useUserData from "../../hooks/useUserData";

export default function Referrals() {
  const queryClient = useQueryClient();
  const user = useUserData();

  const [mode, setMode] = useState('view')

  function onRefer() {
    setMode('create')
  }

  function onSuccess() {
    queryClient.invalidateQueries('referrals');
    setMode('view')
  }

  return (
    <div>
      <ReferralBanner />

      <div className="flex flex-row justify-between items-center my-8">
        {mode === 'view' && (
          <h1 className="font-semibold text-xl">Your Referrals</h1>
        )}

        {mode === 'create' && (
          <h1 className="font-semibold text-xl">Refer a business</h1>
        )}

        <div>
          <div className='font-bold text-xl text-right'>{user.code}</div>
          <div className='text-xs text-gray-400'>Your Partner Code</div>
        </div>
        {/*{mode === 'view' && (*/}
        {/*  <Button type="primary" onClick={onRefer}>Refer a business</Button>*/}
        {/*)}*/}
      </div>

      {mode === 'view' && (
        <YourReferrals />
      )}

      {mode === 'create' && (
        <ReferForm onSuccess={onSuccess} onCancel={() => setMode('view')} />
      )}
    </div>
  );
}

function ReferForm({onSuccess, onCancel}) {
  const [form] = Form.useForm();
  const createApi = useMutation(ApiService.createReferral);

  function onFinish(values) {
    createApi.mutateAsync(values)
      .then(() => {
        onSuccess();
      })
      .catch(error => {
        ApiService.setFormErrors(form, error);
      });
  }

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item name="company_name" label="Business Name" rules={[{required: true}]}>
        <Input />
      </Form.Item>

      <Form.Item name="name" label="Contact Person Name" rules={[{required: true}]}>
        <Input />
      </Form.Item>

      <Form.Item name="mobile" label="Contact Person Phone" rules={[{required: true}]}>
        <Input />
      </Form.Item>

      <Form.Item name="email" label="Contact Person Email">
        <Input />
      </Form.Item>

      <div className="mt-2 pb-8">
        <Button className="mr-2" onClick={onCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit" loading={createApi.isLoading}>Submit Referral</Button>
      </div>
    </Form>
  );
}

function YourReferrals() {
  const query = useQuery('referrals', ApiService.getReferrals);

  if (query.isLoading) {
    return <Skeleton />;
  }

  if (!query.data) {
    return <div>Something went wrong.</div>
  }

  return (
    <div>
      {query.data.data.map(referral => (
        <Card key={referral.id} className="mb-4">
          <div className="flex flex-row justify-between items-center">
            <div>
              <h2 className="font-semibold mb-0">{referral.company_name} / {referral.name}</h2>
              {referral.mobile && (
                <div className="text-gray-500 flex flex-row items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                       className="w-4 h-4 mr-1">
                    <path fillRule="evenodd"
                          d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                          clipRule="evenodd" />
                  </svg>
                  {referral.mobile}
                </div>
              )}
              {referral.email && (
                <div className="text-gray-500 flex flex-row items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                       className="w-4 h-4 mr-1">
                    <path fillRule="evenodd"
                          d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z"
                          clipRule="evenodd" />
                  </svg>
                  {referral.email}
                </div>
              )}
              <div className="text-gray-500">Referred {dayjs.unix(referral.created_unix).fromNow()}</div>
            </div>
            <div className='flex flex-col items-end'>
              {referral.status && (
                <div className='mb-2'>
                  <Tag color={referral.status.color}>{referral.status.text}</Tag>
                </div>
              )}

              {referral.commission_text && (
                <div>
                  <div className='font-semibold'>{referral.commission_text}</div>
                </div>
              )}

              {referral.mrr_text && (
                <div>
                  <div className='text-gray-500 text-right'>{referral.mrr_text.title}</div>
                  <div className='text-gray-500 text-right'>{referral.mrr_text.subtitle}</div>
                </div>
              )}
            </div>
          </div>
        </Card>
      ))}

      {query.data.data.length === 0 && (
        <div className="text-center">
          <p className="text-gray-500">You haven't referred any businesses yet.</p>
        </div>
      )}
    </div>
  );
}

function ReferralBanner() {
  const user = useUserData();

  const steps = [
    {
      title: 'Introduce Ewity to the business, ask them to signup on Ewity',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
             stroke="currentColor"
             className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
        </svg>
      )
    },
    {
      title: `The business can enter your Partner Code (${user.code}) when they subscribe to Ewity`,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
             className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
        </svg>
      ),
    },
    {
      title: 'You get 25% commission for a year!',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
             className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
        </svg>
      ),
    },
  ];

  return (
    <div className="rounded-md shadow-md overflow-hidden">
      <div className="mb-5 bg-blue-900 p-8">
        <div className="text-xl mb-1 text-white">Refer a business!</div>
        <div className="text-blue-200 leading-tight">No setup or support commitments required from you. Our team will
          handle everything! How does it work?
        </div>
      </div>
      <div className="p-5">
        {steps.map((step) => (
          <div className="flex flex-row items-center mb-5" key={step.title}>
            <div className="bg-blue-100 text-blue-500 p-4 rounded-full">
              {step.icon}
            </div>

            <div className="ml-3">{step.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
