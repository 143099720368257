import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { useMutation } from "react-query";
import ApiService from "../../services/ApiService";
import useStore from "../../store";

export default function Login() {
  const navigate = useNavigate();
  const loginMutation = useMutation(ApiService.login);
  const [form] = Form.useForm();
  const setToken = useStore(state => state.setToken);

  function onFinish(values: any) {
    loginMutation.mutateAsync(values)
      .then(response => {
        setToken(response.data.token);
        navigate('/referrals');
      })
      .catch(e => {
        ApiService.setFormErrors(form, e);
      })
  }

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
        Sign in to your Ewity Partner account
      </h2>
      <p className="mt-2 text-sm text-gray-600">
        Or{' '}
        <Link to="/signup">
          create your account for free
        </Link>
      </p>

      <div className="mt-6">
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input.Password />
        </Form.Item>

        <div>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full mt-3"
            loading={loginMutation.isLoading}
          >
            Sign In
          </Button>
        </div>
      </div>
    </Form>
  );
}
