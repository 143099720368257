// @ts-ignore
import cx from 'classnames';
import {Avatar, Dropdown, notification} from "antd";
import useStore from "../../store";
import {NavLink} from "react-router-dom";

const navigation = [
  {name: 'Referrals', href: '/referrals'},
  {name: 'Commissions', href: '/payments'},
]

export default function NavBar() {
  const {user, setUser, setToken} = useStore(state => ({
    user: state.user,
    setUser: state.setUser,
    setToken: state.setToken,
  }));

  function onLogout() {
    setUser(null);
    setToken(null);

    notification.success({
      message: 'Successfully logged out'
    })
  }

  return (
    <div className="border-solid border-b border-gray-200 bg-white">
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="https://app.ewitypos.com/images/pos-icon-1.1-512.png"
                alt="Ewity POS Partners"
              />
            </div>
            <div className="-my-px ml-6 flex space-x-8">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({isActive}) => cx(
                    isActive
                      ? 'border-blue-500 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                  )}
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="ml-6 flex items-center px-2 cursor-pointer hover:bg-gray-100">
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'logout',
                    label: 'Logout',
                    onClick: onLogout,
                  }
                ]
              }}
            >
              <div className="flex flex-row items-center">
                <Avatar>{user.name.charAt(0).toUpperCase()}</Avatar>
                <div className="text-sm ml-2">
                  <div className="text-gray-600">{user.name}</div>
                  <div className="text-gray-500">{user.email}</div>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
