import { notification } from "antd";
import useStore from "../store";

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';
type UrlParams = { [key: string]: any };

export class ApiError extends Error {
  response: Response;
  json: any;

}

function getAccessToken() {
  return useStore.getState().token;
}

function handleError(e: any) {
  if (!(e instanceof ApiError)) {
    console.error('Unknown API Error', e);
    return;
  }

  notification.error({
    message: e.message,
  })
}

function makeUrl(url, params: UrlParams = {}) {
  let urlParams = new URLSearchParams();
  for (let key in params) {
    urlParams.append(key, params[key]);
  }

  if (url.startsWith('/')) {
    url = url.slice(1);
  }

  return `/api/${url}?${urlParams.toString()}`;
}

function makeRequest(url: string, method: Method, body: any = null, urlParams: UrlParams = {}) {
  return fetch(makeUrl(url, urlParams), {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getAccessToken()}`,
    },
    body: body ? JSON.stringify(body) : null
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw response;
      }
    })
    .catch(e => {
      if (e.json) {
        return e.json().then(json => {
          const error = new ApiError(json.message || e.statusText);
          error.response = e;
          error.json = json;

          throw error;
        });
      } else {
        throw e;
      }
    })
    .catch(e => {
      handleError(e);

      throw e;
    });
}

const HttpService = {
  post(url: string, body: any = null) {
    return makeRequest(url, 'POST', body);
  },

  get(url: string, urlParams: UrlParams = {}) {
    return makeRequest(url, 'GET', null, urlParams);
  },
};

export default HttpService;
