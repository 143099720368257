import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import 'antd/dist/reset.css';

import Auth from "./routes/auth";
import Login from "./routes/auth/Login";
import Signup from "./routes/auth/Signup";
import Layout from "./routes/layout";
import Referrals from "./routes/layout/Referrals";

import dayjs from "dayjs";
import Reseller from "./routes/layout/Reseller";
import Payments from "./routes/layout/Payments";

dayjs.extend(require("dayjs/plugin/relativeTime"));

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    element: <Auth />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'signup',
        element: <Signup />,
      }
    ]
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "referrals",
        element: <Referrals />,
      },
      {
        path: 'reseller',
        element: <Reseller />,
      },
      {
        path: 'payments',
        element: <Payments />,
      },
      {
        path: "*",
        element: <div>Not Found</div>,
      }
    ]
  },
]);

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            controlHeight: 40,
          }
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </QueryClientProvider>
  );
}
