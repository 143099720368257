import { useNavigate } from "react-router-dom";
import useStore from "../store";
import { useEffect } from "react";
import ApiService from "../services/ApiService";
import { ApiError } from "../services/HttpService";

export default function useUserData() {
  const navigate = useNavigate();
  const { user, setUser, setToken } = useStore(state => ({
    user: state.user,
    setUser: state.setUser,
    setToken: state.setToken,
  }))
  const token = useStore((state) => state.token);

  useEffect(() => {
    if (!token) {
      navigate('/signup');
      return;
    }

    ApiService.me()
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        if (error instanceof ApiError && error.response.status === 401) {
          // redirect to login, user session is invalid
          setUser(null);
          setToken(null);

          navigate('/login');
        }
      })
  }, [token]);

  return user;
}
