import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { useMutation } from "react-query";
import ApiService from "../../services/ApiService";
import useStore from "../../store";

export default function Signup() {
  const navigate = useNavigate();
  const signupMutation = useMutation(ApiService.signup);
  const [form] = Form.useForm();
  const setToken = useStore(state => state.setToken);

  function onFinish(values: any) {
    signupMutation.mutateAsync(values)
      .then(response => {
        setToken(response.data.token);
        navigate('/referrals');
      })
      .catch(e => {
        ApiService.setFormErrors(form, e);
      })
  }

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
        Create your Ewity Partner account
      </h2>
      <p className="mt-2 text-sm text-gray-600">
        Or{' '}
        <Link to="/login">
          Sign in to your account
        </Link>
      </p>

      <div className="mt-6">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="password2"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input.Password />
        </Form.Item>

        <div>
          <Button type="primary" htmlType="submit" className="w-full mt-3">Sign Up</Button>
        </div>
      </div>
    </Form>
  );
}
