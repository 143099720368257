import React from 'react';
import {useQuery} from "react-query";
import ApiService from "../../services/ApiService";
import {Card, Skeleton} from "antd";
import * as dayjs from "dayjs";

export default function Payments() {
  const query = useQuery('payments', ApiService.getPayments);

  if (query.isLoading) {
    return <Skeleton />;
  }

  if (!query.data) {
    return <div>Something went wrong.</div>
  }

  const result = query.data;

  return (
    <div>
      <div className="rounded-md shadow-md overflow-hidden mb-8">
        <div className="bg-blue-900 p-8">
          <div className="text-xl mb-1 text-white">Commissions</div>
          <div className="text-blue-200 leading-tight">
            Your commissions show up here. You can withdraw your commissions at any time, please contact Ewity
            support to do so.
          </div>

          <div className="text-blue-200 leading-tight mt-5">
            Your current balance is <span className="font-bold">{result.balance_currency} {result.balance}</span>
          </div>
        </div>
      </div>

      {result.data.map(payment => (
        <Card key={payment.id} className="mb-4">
          <div className="flex flex-row justify-between items-center">
            <div>
              <div className='font-semibold'>{payment.number}</div>
              <div className='text-gray-500'>{payment.type}</div>
              <div className='text-gray-500'>{payment.description}</div>
              <div className="text-gray-500">{dayjs.unix(payment.created_unix).fromNow()}</div>
            </div>
            <div>
              {payment.currency} {payment.amount}
            </div>
          </div>
        </Card>
      ))}

      {query.data.data.length === 0 && (
        <div className="text-center">
          <p className="text-gray-500">You haven't received any commissions yet. Refer to start earning!</p>
        </div>
      )}
    </div>
  );
}
