import {useState} from "react";
import {Button, notification} from "antd";
import {useMutation} from "react-query";
import ApiService from "../../services/ApiService";

export default function Reseller() {
  return null;

  const [requested, setRequested] = useState(false);
  const mutate = useMutation(ApiService.requestResellerCall);

  function onRequest() {
    mutate.mutateAsync({})
      .then(() => {
        notification.success({
          message: 'Requested a call',
          description: 'Our team will contact you on your registered mobile number shortly.',
        });

        setRequested(true);
      });
  }

  return (
    <div>
      <ResellerBanner />

      <div className="py-10">
        <div className="text-lg font-semibold">Interested to become a reseller?</div>
        <div>Our team will reach out to you for verification and training before approving your reseller account</div>

        <div>
          <Button
            type="primary"
            className="mt-8"
            onClick={onRequest}
            loading={mutate.isLoading}
            disabled={requested}
          >
            Request a Call
          </Button>
        </div>
      </div>
    </div>
  );
}

function ResellerBanner() {
  const steps = [
    {
      title: 'Approach a business and pitch Ewity. We will provide you with all the necessary content and training.',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
             stroke="currentColor"
             className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
        </svg>
      )
    },
    {
      title: 'If the business is interested, you will help them onboard and get them started with Ewity. 100% of the setup charges go to you.',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
             className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
        </svg>
      ),
    },
    {
      title: '10% of the subscription charges goes to you as commission as long as the business uses Ewity.',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
             className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
        </svg>
      ),
    },
  ];

  return (
    <div className="rounded-md shadow-md overflow-hidden">
      <div className="mb-5 bg-blue-900 p-8">
        <div className="text-xl mb-1 text-white">Become an Ewity Reseller</div>
        <div className="text-blue-200 leading-tight">Earn from Setup and 10% subscription commission for life!</div>
      </div>
      <div className="p-5">
        {steps.map((step) => (
          <div className="flex flex-row items-center mb-5" key={step.title}>
            <div className="bg-blue-100 text-blue-500 p-4 rounded-full">
              {step.icon}
            </div>

            <div className="ml-3">{step.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
